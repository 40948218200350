export default [
  {
    path: '/jobs',
    name: 'jobs-list',
    component: () => import('@/views/job/jobs-list/JobsList.vue'),
    meta: {
      resource: 'Jobs',
      action: 'read',
      breadcrumb: [
        {
          text: 'Jobs',
          active: true,
        },
      ],
    },
  },
  {
    path: '/job/view/:id',
    name: 'jobs-view',
    component: () => import('@/views/job/jobs-view/JobView.vue'),
  },
  // {
  //   path: '/jobs/edit/:id',
  //   name: 'jobs-edit',
  //   component: () => import('@/views/job/jobs-edit/JobEdit.vue'),
  // },
]
