export default [
  {
    path: '/users',
    name: 'users-list',
    component: () => import('@/views/user/users-list/UsersList.vue'),
    meta: {
      resource: 'users',
      action: 'read',
      breadcrumb: [
        {
          text: 'Users',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/view/:id',
    name: 'users-view',
    component: () => import('@/views/user/users-view/UserView.vue'),
    meta: {
      resource: 'users',
      action: 'read',
      breadcrumb: [
        {
          text: 'Users',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/edit/:id',
    name: 'users-edit',
    component: () => import('@/views/user/users-edit/UserEdit.vue'),
    meta: {
      resource: 'users',
      action: 'update',
      breadcrumb: [
        {
          text: 'Users',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/add',
    name: 'users-add',
    component: () => import('@/views/user/users-edit/UserEdit.vue'),
    meta: {
      resource: 'users',
      action: 'create',
      breadcrumb: [
        {
          text: 'Users',
          active: true,
        },
      ],
    },
  },
]
