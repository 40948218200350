import { defineAbility } from '@casl/ability'

export function defineAbilityFor(userData) {
  if (!userData) {
    return defineAbility(can => {
      can('read', 'Auth')
    })
  }
  return defineAbility((can, cannot) => {
    if (userData.role === 'ROLE_ADMIN' || userData.role === 'ROLE_SUPER_ADMIN') {
      can('manage', 'all')
    } else {
      can('read', 'all')
      cannot('update', 'users')
      cannot('create', 'users')
    }
  })
}

const userData = JSON.parse(localStorage.getItem('userData'))
const ability = defineAbilityFor(userData)
export default ability
