import useJwt from '@core/auth/jwt/useJwt'
import axios from '@axios'

const jwtCustomConfig = {
  loginEndpoint: '/login_check',
  registerEndpoint: '/jwt/register',
  refreshEndpoint: '/token/refresh',
  logoutEndpoint: '/jwt/logout',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'token',
  storageRefreshTokenKeyName: 'refresh_token',
}

const { jwt } = useJwt(axios, jwtCustomConfig)
export default jwt
