import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  baseURL: 'https://api.recoventry.uk/api/',
  timeout: 10000,
})

Vue.prototype.$http = axiosIns

export default axiosIns
