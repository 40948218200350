export default [
  {
    path: '/vehicles',
    name: 'vehicles-list',
    component: () => import('@/views/vehicle/vehicles-list/VehiclesList.vue'),
    meta: {
      resource: 'Vehicles',
      action: 'read',
      breadcrumb: [
        {
          text: 'Vehicles',
          active: true,
        },
      ],
    },
  },
  {
    path: '/vehicles/view/:id',
    name: 'vehicles-view',
    component: () => import('@/views/vehicle/vehicles-view/VehicleView.vue'),
  },
]
