export default [
  {
    path: '/customers',
    name: 'customers-list',
    component: () => import('@/views/customer/customers-list/CustomersList.vue'),
    meta: {
      resource: 'Customers',
      action: 'read',
      breadcrumb: [
        {
          text: 'Customers',
          active: true,
        },
      ],
    },
  },
  {
    path: '/customers/view/:id',
    name: 'customers-view',
    component: () => import('@/views/customer/customers-view/CustomerView.vue'),
  },
]
