export default [
  {
    path: '/jobTemplates',
    name: 'job-templates-list',
    component: () => import('@/views/job-templates/job-templates-list/JobTemplatesList.vue'),
    meta: {
      resource: 'Job Templates',
      action: 'read',
      breadcrumb: [
        {
          text: 'Job Templates',
          active: true,
        },
      ],
    },
  },
  {
    path: '/jobTemplates/view/:id',
    name: 'job-templates-view',
    component: () => import('@/views/job-templates/job-templates-view/JobTemplateView.vue'),
  },
  {
    path: '/jobTemplates/add/',
    name: 'job-templates-add',
    component: () => import('@/views/job-templates/job-templates-add/JobTemplateAdd.vue'),
  },
  {
    path: '/jobTemplates/edit/:id',
    name: 'job-templates-edit',
    component: () => import('@/views/job-templates/job-templates-add/JobTemplateAdd.vue'),
  },
]
